import { PlusCircleIcon } from "@heroicons/react/24/solid"
import { Option, Select } from "@material-tailwind/react"
import React from "react"
import Table from "../../molecules/table"


import CreatorController from "../../../services/creator";
import moment from "moment";

export default function FinanceCreatorView() {
  const [transactions, setTransactions] = React.useState<any>([])

  const getTransactions = async () => {
    const result = await CreatorController.getTransactions()
    setTransactions(result?.data?.result || [])
  }

  React.useEffect(() => {
    getTransactions()
  }, [])

  return (
    <React.Fragment>
      <div className='font-bold text-xl'>
        Finance
      </div>

      <div className="grid grid-cols-12 gap-4 my-5">
        <div className="col-span-8">
          <div className="bg-white rounded border shadow-md min-h-[400px] p-5">
            <div className='grid grid-cols-4'>
              <div className="font-bold text-xl">Transactions</div>
              <div className="col-end-5">
                <Select label="Select account">
                  <Option>Demo</Option>
                  <Option>Demo1</Option>
                </Select>
              </div>
            </div>

            <div>
              <Table>
                <Table.HeadRow className="text-grey-50 inter-small-semibold">
                  <Table.HeadCell>Transaction date</Table.HeadCell>
                  <Table.HeadCell className="">
                    Content
                  </Table.HeadCell>
                  <Table.HeadCell className="">
                    Amount
                  </Table.HeadCell>
                  <Table.HeadCell className="">
                    Withdraw fee
                  </Table.HeadCell>
                </Table.HeadRow>

                <Table.Body>
                  {
                    transactions.map((transaction: any, index: number) => (
                      <Table.Row key={index}>
                        <Table.Cell>{
                          moment(transaction.createdAt).format('HH:mm DD/MM/YYYY')}</Table.Cell>
                        <Table.Cell>{transaction.description}</Table.Cell>
                        <Table.Cell>{transaction.amount}</Table.Cell>
                        <Table.Cell>{transaction.withDraw || 0}</Table.Cell>

                      </Table.Row>
                    ))
                  }

                </Table.Body>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-span-4">
          <div className="bg-white rounded border shadow-md min-h-[400px] px-10 py-5">
            <div className="font-bold text-xl mb-10">Bank Cards</div>
            <div className="grid gap-4">
              <div className="border-dashed rounded h-[200px] shadow-md bg-gray-100">
                <div className="grid place-items-center h-full cursor-pointer">
                  <PlusCircleIcon height={60} color={'gray'} />
                </div>
              </div>

              <div className="border-dashed rounded h-[200px] shadow-md bg-gray-100">
                <div className="grid place-items-center h-full cursor-pointer">
                  <PlusCircleIcon height={60} color={'gray'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}